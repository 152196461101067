.Questions_container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 100vh;
    padding: 20px;
    padding-top: 100px;
}

.Questions_form_container {
    margin-top: 10px;
    width: 100%;
    padding: 25px;
    background-color: white;
    color: black;
    border-radius: 10px;
    box-shadow: 0 0 10px #6a2ca8;
    border: 4px solid #6a2ca8;
    background-color: white;
}

.Questions_form {
    width: 100%;
}

.Questions_form_item {
    margin: 15px 0 15px 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Questions_header {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    padding-bottom: 25px;
    font-weight: 600;
}