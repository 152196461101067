@media screen and (max-width: 450px) and (orientation: portrait) {
    .Registry_container {
        height: 100vh;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        flex-direction: column;
    }
    
    .Registry_message_container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 80vw;
        padding: 20px;
        background: linear-gradient(to bottom, #6f2589, #64227c);
        border-radius: 10px;
        box-shadow: 0 0 10px #6a2ca8;
        border: 4px solid #6a2ca8;
        background-color: white;
    }
    
    .Registry_header {
        font-size: 34px;
        font-weight: bold;
    }
    
    .Registry_body {
        font-size: 15px;
        text-align: center;
    }
    
    .Registry_line_divider {
        width: 50%;
        height: 1px;
        background: linear-gradient(to right, transparent, white, transparent);
    }
    
    .Registry_image_gift {
        height: 50px;
    }
    
    .Registry_image_travel {
        height: 50px;
        padding-top: 25px;
    }
}

@media screen and (max-height: 450px) and (orientation: landscape) {
    .Registry_container {
        height: 100vh;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        flex-direction: column;
        padding-top: 30px;
    }
    
    .Registry_message_container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 90vw;
        padding: 10px 0;
        background: linear-gradient(to bottom, #6f2589, #64227c);
        border-radius: 10px;
        box-shadow: 0 0 10px #6a2ca8;
        border: 4px solid #6a2ca8;
        background-color: white;
        padding: 20px;
    }
    
    .Registry_header {
        font-size: 24px;
        font-weight: bold;
    }
    
    .Registry_body {
        font-size: 15px;
        text-align: center;
    }
    
    .Registry_line_divider {
        width: 50%;
        height: 1px;
        background: linear-gradient(to right, transparent, white, transparent);
    }
    
    .Registry_image_gift {
        height: 50px;
        display: none;
    }
    
    .Registry_image_travel {
        height: 50px;
        padding-top: 10px;
    }
}