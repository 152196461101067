.Home_container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 100px 0;
    flex-direction: column;
}

.Home_image {
    max-width: fit-content;
    width: 50%;
}

.Home_text_container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 25px;
    padding: 0 25px 25px 25px;
    flex-direction: column;
    color: white;
    border-radius: 10px;
    width: 100vw;
}

.Home_information-text {
    padding-top: 20px;
    font-size: 22px;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-shadow: 0 0 5px black;
}



.Home_information-text-lg {
    padding: 20px 0 20px 0;
    font-size: 30px;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-shadow: 0 0 5px black;
    flex-direction: column;
}

.Home_line_divider {
    width: 50%;
    height: 1px;
    background: linear-gradient(to right, transparent, white, transparent);
}

.MuiButton-contained {
    color: black;
}

.Home_image_fellowship {
    height: 50px;
    padding: 15px 0;
}