.ImageUploader_container {
    background-color: white;
    height: 100vh;
    width: 100vw;
    color: white;
    background: linear-gradient(to bottom, #090248, #cd3754);
    overflow-y: scroll;

    & .ImageUploader_image-container {
        max-width: 300px;
    }
}

.ImageUploader_sky {
    padding-top: 25px;
    background-image: url('../../Assets/sky.png');
    background-size: 100vh;
    background-repeat: repeat-x;
    height: calc(100vh - 25px);
    width: 100vw;
    overflow-y: scroll;
}

.ImageUploader_image_travel {
    height: 200px;
    padding-top: 25px;
    padding-bottom: 25px;
}

.ImageUploader_line_divider {
    width: 50%;
    height: 1px;
    background: linear-gradient(to right, transparent, white, transparent);
}