@media screen and (max-width: 450px) and (orientation: portrait) {
    .Nav_container {
        background: linear-gradient(to bottom, #090248, #cd3754);
    
        & button {
            color: white;
        }
    }
    
    .Nav_sky {
        background-image: url('../../Assets/sky.png');
        background-size: 100vh;
        background-repeat: repeat-x;
    }

    .Nav_tab_text {
        font-size: 9px !important;
    }

    .MuiTabs-flexContainer {
        align-items: center;
        justify-content: center;
    }

    .MuiTab-fullWidth {
        min-width: 0 !important;
        padding: 0 !important;
    }
}