.Registry_container {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    flex-direction: column;
}

.Registry_message_container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 90vw;
    padding: 100px 0;
    background: linear-gradient(to bottom, #6f2589, #64227c);
    border-radius: 10px;
    box-shadow: 0 0 10px #6a2ca8;
    border: 4px solid #6a2ca8;
    background-color: white;
    padding: 20px;
}

.Registry_header {
    font-size: 64px;
    font-weight: bold;
}

.Registry_body {
    font-size: 22px;
    text-align: center;
}

.Registry_line_divider {
    width: 50%;
    height: 1px;
    background: linear-gradient(to right, transparent, white, transparent);
}

.Registry_image_gift {
    height: 100px;
}

.Registry_image_travel {
    height: 75px;
    padding-top: 25px;
}