.Pictures_container {
    
}

/* General Styles */
body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    background-color: white;
    /* White background */
    color: #333;
}

.Pictures_header-container {

}

/* Header Styles */
.Nav_sky-header {
    position: sticky;
    color: white;
    /* White text for contrast */
    padding: 40px 20px;
    text-align: center;
    /* Subtle shadow for depth */
}

.header h1 {
    margin: 0;
    font-size: 2.5rem;
    font-weight: 700;
}

.header p {
    margin: 10px 0 0;
    font-size: 1.2rem;
    font-weight: 300;
    color: rgba(255, 255, 255, 0.8);
    /* Slightly transparent white for the subtitle */
}

/* Image List Styles */
.image-list-container {
    padding: 15px;
    max-width: 1200px;
    margin: 0 auto;
    background-color: black;
    border-radius: 8px;
}

.image-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 16px;
    margin: unset;
}

.image-list-item {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Subtle shadow */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.image-list-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    /* Stronger shadow on hover */
}

.image-list-item img {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 8px;
}

/* Loading Spinner Styles */
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: white; /* White background behind the loader */
}

/* Full-Screen Dialog Styles */
.dialog-content {
    position: relative;
    padding: 20px;
    background-color: white;
    /* White background for the dialog */
    border-radius: 12px;
    /* Rounded corners */
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
    /* Subtle shadow for the dialog */
}

.dialog-content img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    display: block;
    margin: 0 auto;
    /* Center the image */
}

.close-button {
    background-color: rgba(255, 255, 255, 0.9);
    /* Semi-transparent white */
    border-radius: 50%;
    padding: 8px;
    transition: background-color 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* Subtle shadow for the button */
    z-index: 1;
}

.close-button:hover {
    background-color: rgba(255, 255, 255, 1);
    /* Solid white on hover */
}

.Nav_sky-photos {
    background-image: url('../../Assets/sky.png');
    background-size: 100vh;
    background-repeat: repeat-x;
    position: fixed;
    width: 100vw;
    height: 100vh;
}