@media screen and (max-width: 450px) and (orientation: portrait) {
    .Questions_container {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        height: 100vh;
        padding: 25px;
        padding-top: 100px;
    }
    
    .Questions_form_container {
        margin-top: 10px;
        width: 100%;
        padding: 20px;
        background-color: white;
        color: black;
        border-radius: 10px;
        box-shadow: 0 0 10px #6a2ca8;
        border: 4px solid #6a2ca8;
        background-color: white;
    }
    
    .Questions_form {
        width: 100%;
    }
    
    .Questions_form_item {
        margin: 15px 0 15px 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media screen and (max-height: 450px) and (orientation: landscape) {
    .Questions_container {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        padding: 20px;
        padding-top: 100px;
    }
    
    .Questions_form_container {
        margin-top: 10px;
        width: 100%;
        padding: 25px;
        background-color: white;
        color: black;
        border-radius: 10px;
        box-shadow: 0 0 10px #6a2ca8;
        border: 4px solid #6a2ca8;
        background-color: white;
    }
    
    .Questions_form {
        width: 100%;
    }
    
    .Questions_form_item {
        margin: 15px 0 15px 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}